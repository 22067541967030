.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

 .bottomImg{
   width: 100%;
  
} 
.bannerimg{
    display: inline-block;
    position: absolute;
    width: 50%;
    max-width: 900px;
    right: 0;
    transform: translate(200px,0);
    opacity: 0;
    z-index: 1;
    transition: all 2s ease-in-out .0s;
    
}
.bannertext{
    display: inline-block;
    width: 80%;
    color: rgb(240, 238, 238);
    height: 318px;
    position: relative;

}
.bannertext h1 {
  font-size: 49px;
  line-height: 60px;
  font-weight: 300;
  text-transform: initial;
  color: #fffcfc;
  transform: translate(-50px,0);
  opacity: 0;
  transition: all 1s ease-in-out 1s;
}
.bannertext h1 span {
  display: block;
  font-size: 54px;
  font-weight: 700;
  text-transform: uppercase;
  color: #d6d6d6;
}

.owl-item.active .bannertext h1 {
  transform: translate(0px,0);
  opacity: 1;
}
.bannertext p {
    transform: translate(50px,0);
    opacity: 0;
    transition: all 1s ease-in-out 2s;
    color: #d6d6d6;
}

.owl-item.active .bannertext p {
  transform: translate(0px,0);
  opacity: 1;
}


.owl-item.active .bannerimg {
    transform: translate(0,0);
    opacity: 1;
}
/* .owl-item .bannerimg {
  transform: translate(0,0);
  opacity: 0;
} */
.owl-carousel, .owl-carousel .owl-item {
  -webkit-tap-highlight-color: transparent;
  position: relative;
}
.autoMargin{
  margin-left: auto;
  margin-right: auto;
}
.title{
  font-family: raleway,sans-serif;
  font-size: 38px;
  line-height: 40px;
}
.lazyloaded{
  opacity: 1;
  transition: opacity 400ms;
  transition-delay: 0ms;
}
.owl-carousel, .owl-carousel.owl-loaded {
  display: block;
}
.bannerslider {
  max-width: 1170px;
  margin: 0 auto;
}

.linklist:before{position:absolute;border-left:15px solid #ddd;border-right:15px solid transparent;border-top:10px solid transparent;border-bottom:10px solid transparent;content:"";left:0;top:50%;transform:translate(0,-50%)}
.linklist{width:78%;padding:15px 0 15px 40px;border-left:1px solid #ddd;position:relative}
.linklist:after{position:absolute;border-left:15px solid #fff;border-right:15px solid transparent;border-top:10px solid transparent;border-bottom:10px solid transparent;content:"";left:-2px;top:50%;transform:translate(0,-50%)}
.linklist .linkdiv{display:inline-block;vertical-align:middle;width:38%;}
.linklist .linkdiv span{display:inline-block;vertical-align:middle}
.linklist .linkdiv span.img{width:auto;margin-right:10px;max-width:50px}
.linklist .linkdiv:hover span.img img{transform:rotateY(180deg)}.linklist .linkdiv span.img img{width:100%;transform:rotateY(0deg)}.linklist .linkdiv a.text{width:100%;font-size:22px;line-height:18px;font-weight:500;color:#4d4d4d;display:inline-block;vertical-align: middle;}.linklist .linkdiv a.text:hover{color:#ff6728}.quicklink{}.linklist{}.linklist .linkdiv{}.linkdiv span.img{}.linkdiv span.text{}.linksliderholder{padding:50px 0 0}.linkimg{border:2px solid #000;border-radius:10px}.linkimg img{border-radius:10px}.linkslider{max-width:90%;margin:0 auto}.linkslider .owl-nav{display:none}.linkslider .owl-dots{padding-top:30px;text-align:center}
.linkslider .owl-dots .owl-dot{width:15px;height:8px;border-radius:10px;border:2px solid #660e14;background:#fff;display:inline-block;margin:0 3px}.linkslider .owl-dots .owl-dot.active,.linkslider .owl-dots .owl-dot:focus{background:#ee4b22;border:2px solid #ee4b22}
.tophd h2.title{text-transform:capitalize;margin:0}
.tophd{margin:0;text-align:right;padding-right:5px;padding-top:32px;position:relative}.quickholder .tophd h2.title{text-transform:capitalize;margin:0}
.big-icon{
  
  background: -webkit-linear-gradient(#47c3fc, #d24735);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.quickDiv{
    /* float: right; */
    margin-right: 68px;
    vertical-align: middle;
    /* margin-top: 25px; */
}
.cardNotice {
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  transition: 0.3s;
}

.cardNotice:hover {
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}
.noticeHd{
  background: linear-gradient(45deg, #f0646b, #db232c);
    color: #fff;
    font-size: 28px;
    font-weight: 600;
    text-transform: uppercase;
}
.resultSection{
  background-size: 100% 100%;
  
  padding: 50px;
  background-repeat: no-repeat;
  background-size: auto;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
.aboutSection{
  border-right: 1px solid rgb(184, 182, 182);
}
.aboutHd{
  padding: 20px;
  font-size: 36px;
  font-weight: 600;
}
.profile img {
  width: 150px;
  height: 150px;
  border-radius: 50%
}
.profileImg {
  -webkit-transform: translate3d(0,-50%,0);
  -moz-transform: translate3d(0,-50%,0);
  -o-transform: translate3d(0,-50%,0);
  -ms-transform: translate3d(0,-50%,0);
  transform: translate3d(0,-50%,0);
}
.card-header{
  background-color: rgb(26 160 135);
  color: #fff;
  font-size: 1.4rem;
}
.card-review {
  border: none
}
.second-review {
  border-left: 2px dotted lightgrey;
  border-right: 2px dotted lightgrey
}

.container-review {
  background-color: white;
  text-align: center;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.58);
  border-radius: 16px;
  margin-top: 50px;
  margin-bottom: 50px
}
.header-review {
  padding-top: 35px;
  padding-bottom: 50px;
}
.quoteLeft {
  font-size: 9px;
  color: orangered;
  position: relative;
  bottom: 5px;
  padding-right: 2px;
}
/* @media screen and (min-device-width: 0px) and (max-device-width: 299px) { 
}
@media screen and (min-device-width: 300px) and (max-device-width: 480px) { 
} */
@media screen and (min-device-width: 300px) and (max-device-width: 499px) { 
  .caroselWidth{
    width: 300px;
  }
  .slideCarouselText{
    font-size: 30px !important;
  }
  .slideCarouselText2{
    font-size: 18px !important;
  }
  .bannertext{
    width: 100% !important;
    text-align: center;
    height: 200px;
  }
  .item-slide{
    height: 388px;
    text-align: center;
  }
  .bannerimg{
    position: inherit !important;
    width: 80% !important;
  }
 
}
@media screen and (min-device-width: 500px) and (max-device-width: 599px) { 
  .caroselWidth{
    width: 450px;
  }
  .slideCarouselText{
    font-size: 30px !important;
  }
  .slideCarouselText2{
    font-size: 18px !important;
  }
  .bannertext{
    width: 100% !important;
    text-align: center;
    height: 200px;
  }
  .item-slide{
    height: 388px;
    text-align: center;
  }
  .bannerimg{
    position: inherit !important;
  }
}
@media screen and (min-device-width: 600px) and (max-device-width: 979px) { 
  .caroselWidth{
    width: 500px;
  }
  .slideCarouselText{
    font-size: 30px !important;
  }
  .slideCarouselText2{
    font-size: 18px !important;
  }
  .bannertext{
    width: 100% !important;
    text-align: center;
    height: 200px;
  }
  .item-slide{
    height: 388px;
    text-align: center;
  }
  .bannerimg{
    position: inherit !important;
  }
 
}
@media screen and (min-device-width: 980px) and (max-device-width: 1180px) { 
  .caroselWidth{
    width: 900px;
  }
  .slideCarouselText{
    font-size: 34px !important;
  }
  .slideCarouselText2{
    font-size: 18px !important;
  }
  .bannerimg-item {
    width: 400px !important;
    height: 400px !important;
  }
  .colBannerImg{
    position: inherit !important;
  }
 
}
@media screen and (min-device-width: 1181px)  { 
  .caroselWidth{
    width: 1170px;
  }
  .slideCarouselText2{
    font-size: 20px !important;
  }
  .bannerimg-item {
    width: 400px !important;
    height: 408px !important;
  }
  .colBannerImg{
    position: inherit !important;
  }

  
}


.blinking {
  -webkit-animation: 1s blink ease infinite;
  -moz-animation: 1s blink ease infinite;
  -ms-animation: 1s blink ease infinite;
  -o-animation: 1s blink ease infinite;
  animation: 1s blink ease infinite;
  position: absolute;
    margin-top: -37px;
    margin-left: -23px;
}

@keyframes blink {  
  from { opacity: 1; }
  to { opacity: 0; }
}
#ksTableLavel{
  text-align: right;
}
#ksTableData{
  padding-left: 15px;
}
#ksTable table, th, td {
  border: 1px solid rgb(237, 235, 235);
}
